/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  getDefValues,
  showError,
  getCurrency,
  getImagePath,
  toFixed,
  calculateSkusDiscount,
} from "../../common/functions";
import { toast } from "mdbreact";
import Modal from "../../components/ModalV2";
import { Link, useHistory } from "react-router-dom";
const LoadProduct = (props) => {
  const history = useHistory();
  const [showDForm, setShowDForm] = React.useState(false);
  const toggleShowComment = (event) => {
    if (!showDForm) {
      props.cancelDedicationForm();
    }
    setShowDForm(!showDForm);
  };

  const saveDedicationForm = (event) => {
    if (props.dedicationName !== "" && props.dedicationMessage !== "") {
      props.saveDedicationForm();
      toggleShowComment();
    } else {
      showError(toast, getDefValues().completeFormMessage);
    }
  };

  const combos = calculateSkusDiscount(props.products);
  return (
    <>
      <Modal
        content={
          <>
            <div className="row" style={{ height: 220 }}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                <div className="form-group  card-label ">
                  <label> {getDefValues().nameLabel} </label>
                  <input
                    type="text"
                    className="form-control"
                    value={props.dedicationName}
                    name="dedicationName"
                    onKeyDown={props.changeHandler}
                    onChange={props.changeHandler}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                <div className="form-group  card-label ">
                  <label> {getDefValues().messageLabel} </label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={props.dedicationMessage}
                    name="dedicationMessage"
                    onKeyDown={props.changeHandler}
                    onChange={props.changeHandler}
                  />
                </div>
              </div>
            </div>
          </>
        }
        staticBackdrop={true}
        textLeft={true}
        toogleCloseButton={true}
        formAction={saveDedicationForm}
        btncolor={props.btncolor}
        formButtonLabel={getDefValues().saveLabel}
        toggleModal={toggleShowComment}
        modal={showDForm}
      />
      <div className="row mt-2">
        <div className="col-md-12 col-lg-12">
          <div className="card card-table flex-fill">
            <div className="shop-cart">
              <div>
                <img
                  src={getImagePath("/catalog/images/ico_checkout_cart.svg")}
                  alt="shop-cart"
                />
              </div>
              <h5 className="header-left ">
                {getDefValues().cartLabel.toUpperCase()}
              </h5>
            </div>
            {props.products.length > 0 ? (
              <>
                <h6 className="shop-cart-h6">
                  {getDefValues().cartLabelDesc}{" "}
                </h6>
                <div className="grid-categories">
                  <div
                    className="pagebuilder-column-group"
                    style={props.resumeStyle}
                  >
                    <div className="pagebuilder-column pagebuilder-column-1">
                      <div>
                        <div className="mb-2 background-resume-order margin-resume">
                          <div className="row">
                            <div className="col-12 resume-order-title">
                              <span className="h4-responsive">
                                {getDefValues().orderResumeLabel.toUpperCase()}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7">
                              <div>{getDefValues().subtotalOrderLabel}</div>
                            </div>
                            <div className="col-5 flex items-right text-right">
                              <div>
                                <b>
                                  {getCurrency()}
                                  {props.subtotalLabel}
                                </b>
                              </div>
                            </div>
                          </div>

                          {props.shippingCost > 0 ? (
                            <>
                              <div className="row">
                                <div className="col-10 flex middle">
                                  <div
                                    style={{
                                      lineHeight: "1",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {getDefValues().shippingCostLabel}
                                  </div>
                                </div>
                                <div className="col-2 flex items-right text-right">
                                  <div>
                                    <b>{toFixed(props.shippingCost)}</b>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {props.discount > 0 || props.discountLabel > 0 ? (
                            <>
                              <div className="row">
                                <div className="col-7">
                                  <div>
                                    <span
                                      onClick={props.todggleDiscount}
                                      className="text-underline text-bold pointer"
                                    >
                                      {" "}
                                      {getDefValues().discountLabel}
                                    </span>
                                    {!props.showDiscount ? (
                                      <i
                                        onClick={props.todggleDiscount}
                                        className="ml-1 font-sm pointer fi-rs-angle-down"
                                      />
                                    ) : (
                                      <i
                                        onClick={props.todggleDiscount}
                                        className="ml-1 font-sm pointer fi-rs-angle-up"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="col-5 flex items-right text-right">
                                  <div>
                                    <b>
                                      {"-" + getCurrency()}
                                      {/*props.discount*/}
                                      {toFixed(
                                        parseFloat(props.discount) +
                                          props.discountLabel
                                      )}
                                    </b>
                                  </div>
                                </div>
                              </div>

                              {!props.showDiscount ? (
                                ""
                              ) : (
                                <>
                                  {combos.map((prod) => (
                                    <div className="row discount-mini-label">
                                      <div className="col-6">
                                        <div>
                                          <span className="text-bold">
                                            {prod.productname}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-6 flex items-right text-right">
                                        <div>
                                          <div className="discount-2-span">
                                            <span>
                                              {"-" +
                                                getCurrency() +
                                                toFixed(prod.discount)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  {props.products.map((prod) =>
                                    prod.NEWPRICED ? (
                                      <div className="row discount-mini-label">
                                        <div className="col-6">
                                          <div>
                                            <span className="text-bold">
                                              {prod.productname}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-6 flex items-right text-right">
                                          <div>
                                            <div className="discount-2-span">
                                              <span>
                                                {
                                                  /*prod.priceOld*/

                                                  "-" +
                                                    getCurrency() +
                                                    toFixed(
                                                      prod.quantity *
                                                        prod.price -
                                                        prod.quantity *
                                                          prod.NEWPRICED
                                                    )
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {props.lockCouponButton ? (
                            ""
                          ) : (
                            <div className="row">
                              <div className="col-7 flex middle">
                                <div
                                  style={{
                                    lineHeight: "1",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {getDefValues().couponLabel}

                                  <span
                                    className="coupon-label"
                                    style={{
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      style={{
                                        width: "115px",
                                        paddingLeft: "10px",
                                        border: "0",
                                        height: "26px",
                                        "font-size": "13px",
                                      }}
                                      value={props.couponcode}
                                      name="couponcode"
                                      onKeyDown={props.changeHandler}
                                      onChange={props.changeHandler}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div
                                className="col-5 flex middle items-right text-right"
                                style={{ paddingRight: 10 }}
                              >
                                <div>
                                  <button
                                    disabled={
                                      props.couponcode === "" ? true : false
                                    }
                                    onClick={props.validCoupon}
                                    className={
                                      "btn btn-" +
                                      props.btncolor +
                                      " btn-sm btn-add-coupon"
                                    }
                                  >
                                    {getDefValues().shortbtnaddbutton.toUpperCase()}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {props.discountSTMessages.map((item) => (
                            <div className="row">
                              <div className="col-8 flex items-left text-left">
                                <div
                                  style={{
                                    "line-height": "1",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "13px",
                                    color: "#d50028",
                                  }}
                                >
                                  {item.validcouponcode !== "" ? (
                                    <button
                                      className="transparent-button"
                                      onClick={props.deleteCoupon}
                                      name={item.validcouponcode}
                                    >
                                      <i
                                        style={{ color: "#d50028" }}
                                        className=" fas fa-times-circle"
                                      ></i>{" "}
                                      {item.couponlabel}
                                    </button>
                                  ) : (
                                    <> {item.couponlabel}</>
                                  )}
                                </div>
                              </div>
                              <div className="col-4 flex items-right text-right">
                                <div>
                                  <b>
                                    {"-" + getCurrency() + item.discountamount}
                                  </b>
                                </div>
                              </div>
                            </div>
                          ))}

                          {props.discountSHMessages.length > 0 ? (
                            <div className="row">
                              <div className="col-8 flex items-left text-left">
                                <div
                                  style={{
                                    "line-height": "1",
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: "13px",
                                    color: "#d50028",
                                  }}
                                >
                                  {props.discountSHMessages[0]
                                    .validcouponcode !== "" ? (
                                    <button
                                      className="transparent-button"
                                      onClick={props.deleteCoupon}
                                      name={
                                        props.discountSHMessages[0]
                                          .validcouponcode
                                      }
                                    >
                                      <i
                                        style={{ color: "#d50028" }}
                                        className=" fas fa-times-circle"
                                      ></i>{" "}
                                      {props.discountSHMessages[0].couponlabel}
                                    </button>
                                  ) : (
                                    <>
                                      {" "}
                                      {props.discountSHMessages[0].couponlabel}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-4 flex items-right text-right">
                                <div>
                                  <b>
                                    {"-" +
                                      getCurrency() +
                                      (props.discountShipping >
                                      props.shippingCost
                                        ? toFixed(props.shippingCost)
                                        : toFixed(props.discountShipping))}
                                  </b>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <span className="or-line"></span>
                          <div className="row mb-2 mt-3">
                            <div className="col-7">
                              {getDefValues().totalOrderLabel}
                            </div>
                            <div className="col-5 flex items-right text-right">
                              <b>
                                {getCurrency()}
                                {parseFloat(props.subtotal) -
                                  parseFloat(props.discount) -
                                  parseFloat(props.discountSubtotal) <
                                0
                                  ? toFixed(
                                      0 +
                                        (parseFloat(props.shippingCost) -
                                          parseFloat(props.discountShipping) <
                                        0
                                          ? 0
                                          : parseFloat(props.shippingCost) -
                                            parseFloat(props.discountShipping))
                                    )
                                  : toFixed(
                                      parseFloat(props.subtotal) -
                                        parseFloat(props.discount) -
                                        parseFloat(props.discountSubtotal) -
                                        (props.isDisctountShipp
                                          ? parseFloat(props.discountShipping)
                                          : 0) +
                                        (parseFloat(props.shippingCost) -
                                          parseFloat(props.discountShipping) <
                                        0
                                          ? 0
                                          : parseFloat(props.shippingCost) -
                                            parseFloat(props.discountShipping))
                                    )}
                              </b>
                            </div>
                          </div>
                          {parseFloat(props.subtotal) -
                            parseFloat(props.discount) -
                            parseFloat(props.discountSubtotal) +
                            (props.isDisctountShipp
                              ? parseFloat(props.discountShipping)
                              : 0) <
                          0 ? (
                            <>
                              <span className="or-line"></span>
                              <div className="row">
                                <div className="col-7">
                                  {getDefValues().positiveBalanceLabel}
                                </div>
                                <div className="col-5 flex items-right text-right">
                                  <b>
                                    {getCurrency()}
                                    {toFixed(
                                      parseFloat(props.discount) +
                                        parseFloat(props.discountSubtotal) -
                                        parseFloat(props.subtotal) -
                                        (props.isDisctountShipp
                                          ? parseFloat(props.discountShipping)
                                          : 0)
                                    )}
                                  </b>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="row mb-1">
                          {props.eventListInfo ? (
                            <>
                              <div className="col-12 ">
                                <button
                                  onClick={toggleShowComment}
                                  className={
                                    "btn btn-" +
                                    props.btncolor +
                                    " pagebuilder-column-1"
                                  }
                                >
                                  {getDefValues().showDedicationLabel.toUpperCase()}
                                </button>
                              </div>
                              <div className="col-12 mt-2 ">
                                <button
                                  onClick={() => {
                                    history.push(
                                      "/customer/event/event-details"
                                    );
                                  }}
                                  className={
                                    "btn btn-" +
                                    props.btncolor +
                                    " pagebuilder-column-1"
                                  }
                                >
                                  {getDefValues().showEventList.toUpperCase()}
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="col-12">
                              <Link to="/">
                                <span className="add-more-products-link">
                                  {getDefValues().continueBuyingLabel.toUpperCase()}
                                </span>
                              </Link>
                            </div>
                          )}
                          <div className="col-12 mt-2">
                            <button
                              onClick={props.changePanel}
                              disabled={props.needReview}
                              className={
                                "btn btn-" +
                                props.btncolor +
                                " pagebuilder-column-1"
                              }
                            >
                              {getDefValues().buyLabel.toUpperCase()}
                            </button>
                            {props.needReview && (
                              <div className="mt-1 text-right" style={{fontStyle: "italic"}}>
                                <span className="h6-responsive" >
							                  	<i className="fas fa-exclamation-triangle"></i>{" "} {getDefValues().chectCartLabel}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        {parseFloat(props.subtotal) -
                          parseFloat(props.discount) -
                          parseFloat(props.discountSubtotal) +
                          (props.isDisctountShipp
                            ? parseFloat(props.discountShipping)
                            : 0) <
                        0 ? (
                          <div className="couponmessage mb-2">
                            <div className="row">
                              <div className="col-12">
                                <p>
                                  <b>
                                    {" "}
                                    <i
                                      style={{ color: "#d50028" }}
                                      className=" fas fa-exclamation-circle"
                                    ></i>{" "}
                                    {getDefValues().oneUseOnlyCouponTitle}
                                  </b>
                                </p>
                                <p>
                                  <span>
                                    {getDefValues().oneUseOnlyCouponLabel.replace(
                                      "<positiveBalance>",
                                      toFixed(
                                        parseFloat(props.discount) +
                                          parseFloat(props.discountSubtotal) -
                                          parseFloat(props.subtotal) -
                                          (props.isDisctountShipp
                                            ? parseFloat(props.discountShipping)
                                            : 0)
                                      )
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="pagebuilder-column-group"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="pagebuilder-column"
                      style={{ width: "100%" }}
                    >
                      <div className="row mb-2">
                        <div className="col-12">
                          <table className="table shopping-summery clean">
                            <thead>
                              <tr className="main-heading">
                                <th scope="col">
                                  <span className="ml-2">
                                    {" "}
                                    {getDefValues().shopCartProductLabel.toUpperCase()}{" "}
                                  </span>{" "}
                                </th>
                                <th scope="col"></th>
                                <th scope="col">
                                  {getDefValues().shopCartPriceLabel.toUpperCase()}
                                </th>
                                <th
                                  scope="col"
                                  style={{ paddingRight: "0.5rem" }}
                                >
                                  {getDefValues().shopCartQuantityLabel.toUpperCase()}
                                </th>
                                <th scope="col">
                                  {getDefValues().shopCartSubtotalLabel.toUpperCase()}
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.products.map((item) => (
                                <tr
                                  style={{
                                    background:
                                      item.fixedquantity || item.nostock
                                        ? "#fcffd857"
                                        : "#fff",
                                  }}
                                >
                                  <td className="image product-thumbnail">
                                    <Link
                                      to={
                                        (item.category
                                          ? "/" + item.category + "/"
                                          : "/productDetails/") +
                                        (item.productpath
                                          ? item.productpath
                                          : item.productname)
                                      }
                                    >
                                      <img
                                        alt={item.productname}
                                        src={getImagePath(item.image)}
                                        className="card-img-top"
                                      />
                                    </Link>
                                  </td>
                                  <td className="product-des product-name">
                                    <div className="">
                                      <div className="cart-product-name">
                                        <Link
                                          to={
                                            (item.category
                                              ? "/" + item.category + "/"
                                              : "/productDetails/") +
                                            (item.productpath
                                              ? item.productpath
                                              : item.productname)
                                          }
                                        >
                                          <span className="text-bold text-underline">
                                            {item.productname}
                                          </span>

                                          <p>
                                            <span className="">
                                              {(item.skus
                                                ? getDefValues().packRefLabel +
                                                  ": "
                                                : "SKU: ") + item.productsku}
                                            </span>
                                          </p>

                                          {item.fixedquantity
                                            ? item.fixedquantity > 0 && (
                                                <p className="mb-1">
                                                  <span
                                                    style={{
                                                      minWidth: 40,
                                                      padding: "0.5rem",
                                                    }}
                                                    className={
                                                      "h6-responsive badge rounded-pill badge-warning"
                                                    }
                                                  >
                                                    {" "}
                                                    <i className="fas fa-exclamation-triangle"></i>{" "}
                                                    {getDefValues().minusInventoryLabel.replace(
                                                      "<qty>",
                                                      item.fixedquantity
                                                    )}{" "}
                                                  </span>
                                                </p>
                                              )
                                            : ""}

                                          {item.nostock && (
                                            <p className="mb-1">
                                              <span
                                                style={{
                                                  minWidth: 40,
                                                  padding: "0.5rem",
                                                }}
                                                className={
                                                  "h6-responsive badge rounded-pill badge-warning"
                                                }
                                              >
                                                {" "}
                                                <i className="fas fa-exclamation-triangle"></i>{" "}
                                                {
                                                  getDefValues()
                                                    .outInventoryLabelCart
                                                }{" "}
                                              </span>
                                            </p>
                                          )}
                                        </Link>

                                        {!item.optionsselected ? (
                                          ""
                                        ) : item.optionsselected.length > 0 ? (
                                          <ul className="">
                                            {item.optionsselected.map((op) => (
                                              <li className="mb-1">
                                                <>
                                                  {op.optionLabel ? (
                                                    op.optionLabel + ": "
                                                  ) : (
                                                    <>
                                                      {props.options.map((ol) =>
                                                        ol.OPTIONID ==
                                                        op.optionid
                                                          ? ol.OPTIONNAME + ": "
                                                          : ""
                                                      )}
                                                    </>
                                                  )}
                                                  <span className="text-bold">
                                                    {op.optionvalue}
                                                  </span>
                                                </>
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className="col-text-right"
                                    data-title={getDefValues().shopCartPriceLabel.toUpperCase()}
                                  >
                                    {item.NEWPRICED ? (
                                      <>
                                        <span className="product-price-new-mini">
                                          {getCurrency()}
                                          {toFixed(item.NEWPRICED) + " "}
                                        </span>
                                        <p className="text-center product-price-old-mini">
                                          {getCurrency()}
                                          {toFixed(item.price)}
                                        </p>
                                        {/*	<div  className="discount-span"><span>{item.priceOld }</span> </div>*/}
                                      </>
                                    ) : (
                                      <>
                                        {item.NEWPRICEC1 ? (
                                          <>
                                            <span className="cart-price">
                                              {getCurrency()}
                                              {toFixed(item.price)}
                                            </span>
                                            <div className="flex items-center">
                                              <p
                                                style={{ width: "80px" }}
                                                className="text-center discount-span"
                                              >
                                                {" "}
                                                {item.priceOld}{" "}
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <span className="cart-price">
                                            {getCurrency()}
                                            {toFixed(item.price)}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td
                                    data-title={getDefValues().shopCartQuantityLabel.toUpperCase()}
                                  >
                                    <div className="flex col-text-quantity">
                                      <div className="detail-qty-shopcart">
                                        <button
                                          name={"decrease_" + item.productsku}
                                          onClick={props.updateCart}
                                          className="qty-down"
                                        >
                                          <i className="fas fa-minus"></i>
                                        </button>
                                        <input
                                          type="text"
                                          className="qty-val"
                                          name={"quantity_" + item.productsku}
                                          value={
                                            item.prequantity != null
                                              ? item.prequantity
                                              : item.quantity
                                          }
                                          onChange={props.updateCart}
                                        />
                                        <button
                                          name={"increase_" + item.productsku}
                                          onClick={props.updateCart}
                                          className="qty-up"
                                        >
                                          <i className="fas fa-plus"></i>
                                        </button>
                                      </div>
                                      {item.showUpdate ? (
                                        <button
                                          name={"update_" + item.productsku}
                                          onClick={props.updateCart}
                                          className="confirm-upd"
                                        >
                                          <i className="fas fa-sync-alt"></i>
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                  <td
                                    className="col-text-right"
                                    data-title={getDefValues().shopCartSubtotalLabel.toUpperCase()}
                                  >
                                    <span>
                                      {getCurrency()}
                                      {item.NEWPRICED
                                        ? toFixed(
                                            item.NEWPRICED * item.quantity
                                          )
                                        : toFixed(item.price * item.quantity)}
                                    </span>
                                  </td>
                                  <td
                                    className="col-text-center action"
                                    data-title=""
                                  >
                                    <a
                                      name={"del_" + item.productsku}
                                      onClick={props.updateCart}
                                      className="text-muted"
                                    >
                                      <img
                                        className="icon-trash"
                                        src={getImagePath(
                                          "/catalog/images/ico_trash.svg"
                                        )}
                                        alt="delete-product"
                                        width="28px"
                                        height="29px"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  

                </div>
		      <div className="carrusel_pagina_carro">
                  </div>
              </>
            ) : (
              <>
                {!props.isLoading && (
                  <>
                    <div className="row mt-3">
                      <div className="col-md-12 col-lg-12">
                        {getDefValues().emtpyCartLabel}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12 col-lg-12">
                        <Link to="/">
                          <span className="add-more-products-link">
                            {getDefValues().startBuyingLabel.toUpperCase()}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
LoadProduct.propTypes = {};
export default LoadProduct;
